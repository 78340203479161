import React from "react"
import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import { About, Donate, Home, Projects, War } from "./pages"
import { Navigation } from "./components"
import { useTitle } from "hooks"
import "app.css"

export enum RouteConstants {
  HOME = "/",
  ABOUT = "/about",
  PROJECTS = "/projects",
  WAR = "/war",
  DONATE = "/donate",
}

export const App = () => {
  const location = useLocation()
  const navigate = useNavigate()
  useTitle()

  React.useEffect(() => {
    if (
      !Object.values(RouteConstants).includes(
        location.pathname as RouteConstants,
      )
    ) {
      navigate("/")
    }
  }, [location.pathname])

  return (
    <main>
      <Navigation />
      <Routes>
        <Route path={RouteConstants.ABOUT} element={<About />} />
        <Route path={RouteConstants.PROJECTS} element={<Projects />} />
        <Route path={RouteConstants.HOME} element={<Home />} />
        <Route path={RouteConstants.WAR} element={<War />} />
        <Route path={RouteConstants.DONATE} element={<Donate />} />
      </Routes>
    </main>
  )
}
