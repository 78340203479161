import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHouseCircleExclamation,
  faPersonRunning,
  faHospital,
  faPeopleRoof,
  faHandHoldingDollar,
  faCity,
  faUtensils,
  faShirt,
  faPrescriptionBottleMedical,
} from "@fortawesome/free-solid-svg-icons"
import { H3, H4, Page, FlexRow, colors } from "components"
import { LanguageContext } from "context"

export const War = () => {
  const startDate = new Date("24 February 2022")
  const today = new Date()
  const days = Math.floor(
    (today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
  )
  const {
    translation: { war },
  } = React.useContext(LanguageContext)

  return (
    <WarPage>
      <Content>
        <Intro>{war.intro1}</Intro>
        <StatsBlock>
          <div>
            <FontAwesomeIcon icon={faHouseCircleExclamation} />
            <H4>{war.stat1}</H4>
          </div>
          <div>
            <FontAwesomeIcon icon={faPersonRunning} />
            <H4>{war.stat2}</H4>
          </div>
          <div>
            <FontAwesomeIcon icon={faHospital} />
            <H4>{war.stat3}</H4>
          </div>
          <div>
            <FontAwesomeIcon icon={faPeopleRoof} />
            <H4>{war.stat4}</H4>
          </div>
        </StatsBlock>
        <Intro>
          {war.intro2} {days} {war.intro3}
        </Intro>
        <StatsBlock>
          <div>
            <FontAwesomeIcon icon={faHandHoldingDollar} />
            <H4>{war.result1}</H4>
          </div>
          <div>
            <FontAwesomeIcon icon={faCity} />
            <H4>{war.result2}</H4>
          </div>
          <div>
            <FontAwesomeIcon icon={faUtensils} />
            <H4>{war.result3}</H4>
          </div>
          <div>
            <FontAwesomeIcon icon={faShirt} />
            <H4>{war.result4}</H4>
          </div>
          <div>
            <FontAwesomeIcon icon={faPrescriptionBottleMedical} />
            <H4>{war.result5}</H4>
          </div>
        </StatsBlock>
      </Content>
    </WarPage>
  )
}

const WarPage = styled(Page)`
  background-color: ${colors.offblack};
  padding: 0 32px;
  overflow: auto;
`
const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto 32px;
  position: relative;
  top: 104px;
  color: ${colors.offwhite};
  z-index: -1;
`
const Intro = styled(H3)`
  font-size: 2.25rem;
  text-align: center;
  max-width: 748px;
  margin: 0 auto;
`
const StatsBlock = styled(FlexRow)`
  margin: 64px 0 32px;
  flex-flow: row wrap;
  align-items: flex-start;
  > div {
    padding: 0px 16px;
    margin: 0 8px 40px;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    min-width: 200px;
    width: 200px;
    > svg {
      color: ${colors.red};
      height: 64px;
      margin-bottom: 16px;
    }
  }
`
