import React, { ReactChild } from "react"
import { DEVICE } from "../constants"

export enum DeviceTypes {
  DESKTOP = "desktop",
  TABLET_LANDSCAPE = "tablet-landscape",
  TABLET_PORTRAIT = "tablet-portrait",
  MOBILE_LANDSCAPE = "mobile-landscape",
  MOBILE_PORTRAIT = "mobile-portrait",
}

const DeviceContext = React.createContext<DeviceTypes>(DeviceTypes.DESKTOP)

const handleResize = () => {
  const width = window.innerWidth
  const height = window.innerHeight
  if (width > DEVICE.DESKTOP || width === undefined) {
    return DeviceTypes.DESKTOP
  } else if (
    width > DEVICE.TABLET &&
    height > DEVICE.MOBILE &&
    width > height
  ) {
    return DeviceTypes.TABLET_LANDSCAPE
  } else if (
    height > DEVICE.TABLET &&
    width > DEVICE.MOBILE &&
    height > width
  ) {
    return DeviceTypes.TABLET_PORTRAIT
  } else if (width > height) {
    return DeviceTypes.MOBILE_LANDSCAPE
  } else {
    return DeviceTypes.MOBILE_PORTRAIT
  }
}

const DeviceContextProvider = ({ children }: { children: ReactChild }) => {
  const [device, setDeviceType] = React.useState<DeviceTypes>(handleResize())

  const resizeLayout = () => {
    setDeviceType(handleResize())
  }

  React.useLayoutEffect(() => {
    window.addEventListener("resize", resizeLayout)
    return () => window.removeEventListener("resize", resizeLayout)
  }, [])

  return (
    <DeviceContext.Provider value={device}>{children}</DeviceContext.Provider>
  )
}

export { DeviceContext, DeviceContextProvider }
