import React from "react"
import styled from "styled-components"
import {
  P,
  H2,
  H3,
  H4 as dsH4,
  Page,
  colors,
  Button,
  Download,
} from "components"
import { DeviceContext, DeviceTypes, LanguageContext } from "context"

const enum Currency {
  HRYVNIA = "Гривня",
  DOLLARS = "Dollars",
  EUROS = "Euros",
  ZLOTY = "Złoty",
}

// const copystrings = [
//   "Caritas Spes, Kyiv, IBAN - UA483052990000026008006402135, Bank - Privat Bank, Swift - ",
// ]

export const Donate = () => {
  const device = React.useContext(DeviceContext)
  const { translation } = React.useContext(LanguageContext)
  const { donate } = translation
  const [activeMenu, setActiveMenu] = React.useState(Currency.DOLLARS)
  const currencyInfo = (currency: Currency) => {
    switch (currency) {
      case Currency.HRYVNIA:
        return (
          <>
            <H4>{donate.orgname}</H4>
            <P>{donate.org}</P>
            <H4>IBAN</H4>
            <P>UA483052990000026008006402135</P>
            <H4>{donate.bankname}</H4>
            <P>{donate.bank}</P>
            <H4>{donate.usreou}</H4>
            <P>20422430</P>
          </>
        )
      case Currency.DOLLARS:
        return (
          <>
            <H4>{donate.orgname}</H4>
            <P>{donate.org}</P>
            <H4>IBAN</H4>
            <P>UA813052990000026002046410462</P>
            <H4>{donate.bankname}</H4>
            <P>{donate.bank}</P>
            <H4>SWIFT</H4>
            <P>PBANUA2X</P>
            <Download href="/files/caritas_spes_USD.pdf" download>
              {donate.moreInfo}
            </Download>
          </>
        )
      case Currency.EUROS:
        return (
          <>
            <H4>{donate.orgname}</H4>
            <P>{donate.org}</P>
            <H4>IBAN</H4>
            <P>UA883052990000026001006416300</P>
            <H4>{donate.bankname}</H4>
            <P>{donate.bank}</P>
            <H4>SWIFT</H4>
            <P>PBANUA2X</P>
            <Download href="/files/caritas_spes_EUR.pdf" download>
              {donate.moreInfo}
            </Download>
          </>
        )
      case Currency.ZLOTY:
        return (
          <>
            <H4>{donate.orgname}</H4>
            <P>{donate.org}</P>
            <H4>IBAN</H4>
            <P>UA673052990000026007046406664</P>
            <H4>{donate.bankname}</H4>
            <P>{donate.bank}</P>
            <H4>SWIFT</H4>
            <P>PBANUA2X</P>
            <Download href="/files/caritas_spes_PLZ.pdf" download>
              {donate.moreInfo}
            </Download>
          </>
        )
      default:
        return <></>
    }
  }
  const info = currencyInfo(activeMenu)

  return (
    <DonatePage
      isMobile={
        device.startsWith("mobile") || device === DeviceTypes.TABLET_PORTRAIT
      }>
      <InfoWrapper>
        <H2 style={{ color: colors.red }}>{donate.h2}</H2>
        <H3>{donate.address}</H3>
        <P>{donate.frVitalii}</P>
        <P>{donate.str}</P>
        <P>{donate.cityOblastPostal}</P>
        <P>{donate.country}</P>
        <P>caritasspes.ukraina@gmail.com</P>
        <Menu>
          <Tab
            active={activeMenu === Currency.HRYVNIA}
            style={{ gridArea: "hrynvia", borderTopLeftRadius: "16px" }}
            onClick={() => setActiveMenu(Currency.HRYVNIA)}>
            <P>{Currency.HRYVNIA}</P>
          </Tab>
          <Tab
            active={activeMenu === Currency.DOLLARS}
            style={{ gridArea: "dollars" }}
            onClick={() => setActiveMenu(Currency.DOLLARS)}>
            <P>{Currency.DOLLARS}</P>
          </Tab>
          <Tab
            active={activeMenu === Currency.EUROS}
            style={{ gridArea: "euros" }}
            onClick={() => setActiveMenu(Currency.EUROS)}>
            <P>{Currency.EUROS}</P>
          </Tab>
          <Tab
            active={activeMenu === Currency.ZLOTY}
            style={{
              gridArea: "zloty",
              borderBottom: "none",
              borderBottomLeftRadius: "16px",
            }}
            onClick={() => setActiveMenu(Currency.ZLOTY)}>
            <P>{Currency.ZLOTY}</P>
          </Tab>
          <Content>{info}</Content>
        </Menu>
        <a href="https://paypal.me/francyska" target="_blank">
          <Button style={{ width: "200px" }}>PayPal</Button>
        </a>
      </InfoWrapper>
    </DonatePage>
  )
}

const DonatePage = styled(Page)<{ isMobile: boolean }>`
  background: url("/images/war/IMG_7921.webp");
  background-size: cover;
  background-position: 0.5 0.4;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 16px;
  height: auto;
  overflow: auto;
  > article {
    width: ${({ isMobile }) => (isMobile ? "calc(100% - 32px)" : "50%")};
    text-align: ${({ isMobile }) => (isMobile ? "center" : "left")};
    margin: ${({ isMobile }) =>
      isMobile ? "104px auto 32px" : "0px 0px 32px"};
  }
`
const Menu = styled.div`
  width: 100%;
  color: ${colors.offwhite};
  text-align: left;
  box-sizing: border-box;
  display: grid;
  margin: 32px 0;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas: "hrynvia content" "dollars content" "euros content" "zloty content";
  background-color: ${colors.red};
  border-radius: 16px;
  @media screen and (max-width: 370px) {
    p {
      font-size: 0.75em;
    }
  }
`
const Tab = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${colors.offwhite};
  background-color: ${({ active }) => (active ? colors.red : "#555")};
  color: ${({ active }) => (active ? colors.offwhite : colors.offwhite)};
  border-right: ${({ active }) => (active ? "none" : "1px solid #DDD")};
  border-collapse: collapse;
  cursor: ${({ active }) => (active ? "initial" : "pointer")};
  > p {
    text-transform: uppercase;
  }
  &:hover {
    background-color: ${({ active }) => (active ? "inherit" : "#AAA")};
  }
`
const H4 = styled(dsH4)`
  color: ${colors.offwhite};
  text-transform: uppercase;
`
const Content = styled.div`
  grid-area: content;
  padding: 8px 8px 8px 16px;
  box-sizing: border-box;
  > p {
    margin-bottom: 8px;
  }
`
const InfoWrapper = styled.article`
  max-width: 410px;
  border-radius: 16px;
  backdrop-filter: blur(10px) brightness(60%);
  width: 100%;
  height: fit-content;
  padding: 16px;
  color: #ddd;
  > h3 {
    margin: 32px 0 8px;
    color: ${colors.offwhite};
  }
  > p {
    color: ${colors.offwhite};
  }
`
