import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, EffectCreative } from "swiper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons"
import { P, H1, Button } from "components"
import { DeviceContext, DeviceTypes, LanguageContext } from "context"
import "swiper/css"

const ANIMATION_TIME = 6000

export const Home = () => {
  const navigate = useNavigate()
  const device = React.useContext(DeviceContext)
  const collapsed =
    device.startsWith("mobile") || device === DeviceTypes.TABLET_PORTRAIT
  const { translation } = React.useContext(LanguageContext)
  const { home } = translation

  return (
    <Carousel>
      <Overlay className="overlay" />
      <ImageWrapper>
        <Swiper
          grabCursor={true}
          slidesPerView={1}
          modules={[Autoplay, EffectCreative]}
          allowTouchMove={false}
          effect={"creative"}
          speed={1000}
          loop={true}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: ["-120%", 0, -500],
            },
            next: {
              shadow: true,
              translate: ["120%", 0, -500],
            },
          }}
          autoplay={{
            delay: ANIMATION_TIME,
            disableOnInteraction: false,
          }}>
          <SwiperSlide>
            <Image src="/images/war/IMG_7901.webp" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src="/images/war/IMG_0018.webp" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <Image
              src="/images/war/IMG_7715.webp"
              style={{ objectPosition: "30% top" }}
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <Image src="/images/war/IMG_7765.webp" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src="/images/war/IMG_8215.webp" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src="/images/war/IMG_9518.webp" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <Image
              src="/images/war/IMG_0075.webp"
              style={{ objectPosition: "30% top" }}
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <Image src="/images/war/IMG_7442.webp" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src="/images/war/IMG_8349.webp" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src="/images/war/IMG_9774.webp" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <Image src="/images/war/IMG_9929.webp" alt="" />
          </SwiperSlide>
        </Swiper>
      </ImageWrapper>
      <TextWrapper collapsed={collapsed}>
        <Headline>
          <H1 style={{ color: "white" }}>
            {home.headline1}
            <br />
            {home.headline2}
            <br />
            {home.headline3}
          </H1>
        </Headline>
        <CTA>
          <Button onClick={() => navigate("/donate")}>
            {home.donate} <FontAwesomeIcon icon={faHandHoldingDollar} />
          </Button>
        </CTA>
      </TextWrapper>
      {!collapsed && <Copyright>© {home.copyright}</Copyright>}
    </Carousel>
  )
}

const Carousel = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: -1;
  background-color: #333;
`
const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
`
const TextWrapper = styled.article<{
  collapsed: boolean
}>`
  position: relative;
  transform: translateY(-100vh);
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: ${({ collapsed }) => (collapsed ? "flex" : "grid")};
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: ". ." "headline cta";
  justify-items: ${({ collapsed }) => (collapsed ? "center" : "initial")};
  align-items: end;
  z-index: 3;
  padding: 16px 16px 32px;
  box-sizing: border-box;
  > :first-child {
    text-align: ${({ collapsed }) => (collapsed ? "center" : "initial")};
    justify-content: ${({ collapsed }) =>
      collapsed ? "center !important" : "flex-start"};
    width: ${({ collapsed }) => (collapsed ? "100%" : "initial")};
    margin-bottom: ${({ collapsed }) => (collapsed ? "32px" : "0")};
    padding: ${({ collapsed }) => (collapsed ? "0" : "0 16px")};
  }
  > :last-child {
    justify-content: ${({ collapsed }) =>
      collapsed ? "center !important" : "flex-end"};
    width: ${({ collapsed }) => (collapsed ? "100%" : "initial")};
    margin-right: ${({ collapsed }) => (collapsed ? "-16px" : "initial")};
  }
`
const Headline = styled.div`
  display: flex;
  grid-area: headline;
  margin-left: 16px;
`
const CTA = styled.div`
  grid-area: cta;
  padding: 0 16px;
  display: flex;
  justify-content: flex-end;
`
const ImageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  pointer-events: none;
  z-index: 1;
`
const Image = styled.img`
  display: block;
  object-fit: cover;
  object-position: top center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`
const Copyright = styled(P)`
  position: absolute;
  transform: rotate(-90deg);
  bottom: 72px;
  left: -40px;
  color: white;
  z-index: 3;
`
