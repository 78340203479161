import styled from "styled-components"

export const SinglePage = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: -1;
`
export const Page = styled(SinglePage)`
  min-height: 100vh;
`
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
