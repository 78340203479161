import React from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import { Page, P, H3 as dsH3, H4, colors } from "components"
import { DeviceContext, DeviceTypes, LanguageContext } from "context"
import "swiper/css/bundle"

export const Projects = () => {
  const device = React.useContext(DeviceContext)
  const ismobile =
    device.startsWith("mobile") || device === DeviceTypes.TABLET_PORTRAIT
  const { translation } = React.useContext(LanguageContext)
  const { projects } = translation

  return (
    <ProjectsPage>
      <H3>{projects.daycare}</H3>
      <P style={{ margin: "4px 0 32px", color: colors.red }}>
        {projects.daycareloc}
      </P>
      <Swiper
        grabCursor={true}
        spaceBetween={16}
        navigation={ismobile ? true : false}
        slidesPerView="auto"
        modules={[Navigation]}
        className="projects">
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/daycare/0077.webp"
              alt={projects.daycare1}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4>{projects.daycare1}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/daycare/0084.webp"
              alt={projects.daycare2}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4>{projects.daycare2}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/daycare/1208.webp"
              alt={projects.daycare3}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4>{projects.daycare3}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/daycare/1236.webp"
              alt={projects.daycare4}
              style={{ objectPosition: "center bottom" }}
            />
            <figcaption>
              <H4>{projects.daycare4}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/daycare/8915.webp"
              alt={projects.daycare5}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4>{projects.daycare5}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
      </Swiper>

      <H3 style={{ margin: "64px 0px 0px" }}>{projects.familycenter}</H3>
      <P style={{ margin: "4px 0 32px", color: colors.red }}>
        {projects.familycenterloc}
      </P>
      <Swiper
        grabCursor={true}
        spaceBetween={16}
        navigation={ismobile ? true : false}
        slidesPerView="auto"
        modules={[Navigation]}
        className="projects">
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/familycenter/orphanage1.webp"
              alt={projects.familycenter1}
              style={{ objectPosition: "center bottom" }}
            />
            <figcaption>
              <H4>{projects.familycenter1}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/familycenter/orphanage2.webp"
              alt={projects.familycenter2}
              style={{ objectPosition: "center bottom" }}
            />
            <figcaption>
              <H4>{projects.familycenter2}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/familycenter/orphanage3.webp"
              alt={projects.familycenter3}
              style={{ objectPosition: "center bottom" }}
            />
            <figcaption>
              <H4>{projects.familycenter3}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/familycenter/orphanage4.webp"
              alt={projects.familycenter4}
              style={{ objectPosition: "center top" }}
            />
            <figcaption>
              <H4>{projects.familycenter4}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
      </Swiper>

      <H3 style={{ margin: "64px 0px 0px" }}>{projects.shelter}</H3>
      <P style={{ margin: "4px 0 32px", color: colors.red }}>
        {projects.shelterLoc}
      </P>
      <Swiper
        grabCursor={true}
        spaceBetween={16}
        navigation={ismobile ? true : false}
        slidesPerView="auto"
        modules={[Navigation]}
        className="projects">
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/shelter/IMG_5037.webp"
              alt={projects.shelter1}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4>{projects.shelter1}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/shelter/IMG_5047.webp"
              alt={projects.shelter2}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4></H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/shelter/IMG_5061.webp"
              alt={projects.shelter3}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4>{projects.shelter3}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/shelter/IMG_5081.webp"
              alt={projects.shelter4}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4></H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
      </Swiper>

      <H3 style={{ margin: "64px 0px 0px" }}>{projects.children}</H3>
      <P style={{ margin: "4px 0 32px", color: colors.red }}>
        {projects.childrenLoc}
      </P>
      <Swiper
        grabCursor={true}
        spaceBetween={16}
        navigation={ismobile ? true : false}
        slidesPerView="auto"
        modules={[Navigation]}
        className="projects">
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/svitlytsia/IMG_5232.webp"
              alt={projects.children1}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4>{projects.children1}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/svitlytsia/svitlytsia1.webp"
              alt={projects.children2}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4>{projects.children2}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/svitlytsia/svitlytsia2.webp"
              alt={projects.children3}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4>{projects.children3}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/svitlytsia/svitlytsia3.webp"
              alt={projects.children4}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4>{projects.children4}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/svitlytsia/svitlytsia4.webp"
              alt={projects.children5}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4>{projects.children5}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/svitlytsia/svitlytsia27.webp"
              alt={projects.children6}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4>{projects.children6}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
        <SwiperSlide>
          <ProjectCard ismobile={ismobile}>
            <div />
            <img
              src="/images/svitlytsia/svitlytsia55.webp"
              alt={projects.children6}
              style={{ objectPosition: "center center" }}
            />
            <figcaption>
              <H4>{projects.children6}</H4>
            </figcaption>
          </ProjectCard>
        </SwiperSlide>
      </Swiper>
    </ProjectsPage>
  )
}

const ProjectsPage = styled(Page)`
  background-color: ${colors.offblack};
  padding: 104px 32px 64px;
  overflow: auto;
  color: white;
`
const H3 = styled(dsH3)`
  font-weight: 700;
`
const ProjectCard = styled.figure<{ ismobile: boolean }>`
  height: ${({ ismobile }) => (ismobile ? "calc(60vw - 64px)" : "30vw")};
  width: ${({ ismobile }) => (ismobile ? "calc(100vw - 64px)" : "40vw")};
  position: relative;
  overflow: hidden;
  margin: 0;
  &:hover {
    > img {
      transform: scale(1.2);
      opacity: 0.7;
    }
  }
  > img {
    transform: scale(1);
    transition: transform 2s, opacity 2s;
    height: ${({ ismobile }) => (ismobile ? "calc(60vw - 64px)" : "30vw")};
    width: ${({ ismobile }) => (ismobile ? "calc(100vw - 64px)" : "40vw")};
    object-fit: cover;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    padding: 0;
  }
  > figcaption {
    text-align: left;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 16px 32px;
    z-index: 4;
  }
  > div {
    height: ${({ ismobile }) => (ismobile ? "calc(60vw - 64px)" : "30vw")};
    width: ${({ ismobile }) => (ismobile ? "calc(100vw - 64px)" : "40vw")};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    box-shadow: 0px -15px 25px ${colors.offblack} inset,
      -5px 5px 25px ${colors.offblack} inset;
  }
`
