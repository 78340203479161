import styled from "styled-components"
import { Link as RRLink } from "react-router-dom"
import { SOURCE_SANS, OPEN_SANS } from "../constants"

export const colors = {
  red: "#CD1626",
  yellow: "#f9fd00",
  offwhite: "#FAFAFA",
  offblack: "#222",
}

export const P = styled.p`
  font-family: ${SOURCE_SANS};
  margin: 0;
  @media screen and (max-width: 376px) {
    font-size: 0.85em;
  }
`
export const H1 = styled.h1`
  font-family: ${OPEN_SANS};
  font-size: 10vmin;
  font-weight: 700;
  color: ${colors.offwhite};
  line-height: 0.9;
  margin: 0;
`
export const H2 = styled.h1`
  font-family: ${OPEN_SANS};
  font-size: 7vmin;
  font-weight: 700;
  color: ${colors.offwhite};
  line-height: 1;
  margin: 0;
`
export const H3 = styled.h3`
  font-family: ${OPEN_SANS};
  font-weight: 600;
  font-size: 5vmin;
  color: ${colors.offwhite};
  margin: 0;
`
export const H4 = styled.h4`
  font-family: ${OPEN_SANS};
  font-weight: 700;
  color: ${colors.offwhite};
  margin: 0;
  @media screen and (max-width: 376px) {
    font-size: 0.75em;
  }
`
export const Link = styled(RRLink)`
  font-family: ${OPEN_SANS};
  font-weight: 800;
  text-decoration: none;
  color: ${colors.red};
  margin: 0 4px;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 16px;
  &:hover {
    color: black;
    background-color: ${colors.yellow};
  }
`
export const Button = styled.button`
  padding: 16px 20px;
  border-radius: 28px;
  border: none;
  background-color: ${colors.yellow};
  font-family: ${OPEN_SANS};
  font-weight: 800;
  font-size: 1.25rem;
  cursor: pointer;
  &:hover {
    background-color: black;
    color: ${colors.yellow};
  }
`
export const Download = styled.a`
  font-family: ${OPEN_SANS};
  font-weight: 700;
  text-transform: uppercase;
  color: ${colors.yellow};
  cursor: pointer;
  margin: 12px 0 8px;
  text-decoration: none;
`
