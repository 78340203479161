import React from "react"
import styled from "styled-components"
import { useNavigate, useSearchParams } from "react-router-dom"
import { FontAwesomeIcon as fasIcon } from "@fortawesome/react-fontawesome"
import {
  faLanguage,
  faChevronDown,
  faChevronUp,
  faBars,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons"
import Flags from "country-flag-icons/react/3x2"
import { DeviceContext, LanguageContext, Languages } from "context"
import { Link, colors } from "./Typography"

export const Navigation = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const langURI = searchParams.toString()

  const { language, selectLanguage, translation } =
    React.useContext(LanguageContext)
  const { nav } = translation
  const logo =
    language === Languages.UKRAINIAN ? (
      <img
        src="/images/logos/karitas-kyiv-zhytomyr.svg"
        alt="link to home - Caritas-Spes Kyiv Zhytomyr Diocese logo"
        role="link to home"
        style={{ height: "30px", cursor: "pointer", marginRight: "auto" }}
        onClick={() => navigate(`/`)}
        onKeyDown={e => {
          if (e.key === "Enter") navigate(`/`)
        }}
        tabIndex={0}
      />
    ) : language === Languages.POLISH ? (
      <img
        src="/images/logos/caritas-kijowsko-zytomierska.svg"
        alt="link to home - Caritas-Spes Kyiv Zhytomyr Diocese logo"
        role="link to home"
        style={{ height: "30px", cursor: "pointer", marginRight: "auto" }}
        onClick={() => navigate(`/?${langURI}`)}
        onKeyDown={e => {
          if (e.key === "Enter") navigate(`/?${langURI}`)
        }}
        tabIndex={0}
      />
    ) : (
      <img
        src="/images/logos/caritas-kyiv-zhytomyr.svg"
        alt="link to home - Caritas-Spes Kyiv Zhytomyr Diocese logo"
        role="link to home"
        style={{ height: "30px", cursor: "pointer", marginRight: "auto" }}
        onClick={() => navigate(`/?${langURI}`)}
        onKeyDown={e => {
          if (e.key === "Enter") navigate(`/?${langURI}`)
        }}
        tabIndex={0}
      />
    )

  const device = React.useContext(DeviceContext)
  const ispopout = !!(
    device.startsWith("mobile") || device === "tablet-portrait"
  )

  const [languageMenu, setLanguageMenu] = React.useState(false)
  const [mobileMenu, setMobileMenu] = React.useState(false)

  const toggleLanguageMenu = () => setLanguageMenu(prev => !prev)
  const toggleMobileMenu = () => setMobileMenu(prev => !prev)

  return (
    <Navbar>
      {logo}
      {ispopout ? (
        <>
          <Hamburger icon={faBars} onClick={toggleMobileMenu} />
          {mobileMenu && (
            <>
              <Overlay onClick={() => setMobileMenu(false)} />
              <Popout>
                <Xmark
                  icon={faCircleXmark}
                  style={{ margin: "24px 0" }}
                  onClick={() => setMobileMenu(false)}
                />
                <Link to={`/?${langURI}`} onClick={() => setMobileMenu(false)}>
                  {nav.home}
                </Link>
                <Link
                  to={`/about?${langURI}`}
                  onClick={() => setMobileMenu(false)}>
                  {nav.about}
                </Link>
                <Link
                  to={`/projects?${langURI}`}
                  onClick={() => setMobileMenu(false)}>
                  {nav.projects}
                </Link>
                <Link
                  to={`/war?${langURI}`}
                  onClick={() => setMobileMenu(false)}>
                  {nav.warInUkraine}
                </Link>
                <Link
                  to={`/donate?${langURI}`}
                  onClick={() => setMobileMenu(false)}>
                  {nav.donate}
                </Link>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <UA
                    current={language === Languages.UKRAINIAN}
                    onClick={() => {
                      selectLanguage(Languages.UKRAINIAN)
                    }}
                    className="mobile"
                  />
                  <US
                    current={language === Languages.ENGLISH}
                    onClick={() => {
                      selectLanguage(Languages.ENGLISH)
                    }}
                    className="mobile"
                  />
                  <PL
                    current={language === Languages.POLISH}
                    onClick={() => {
                      selectLanguage(Languages.POLISH)
                    }}
                    className="mobile"
                  />
                </div>
              </Popout>
            </>
          )}
        </>
      ) : (
        <>
          <UL>
            <li>
              <Link to={`/about?${langURI}`}>{nav.about}</Link>
            </li>
            <li>
              <Link to={`/projects?${langURI}`}>{nav.projects}</Link>
            </li>
            <li>
              <Link to={`/war?${langURI}`}>{nav.war}</Link>
            </li>
            <li>
              <Link to={`/donate?${langURI}`}>{nav.donate}</Link>
            </li>
          </UL>
          <LanguageWrapper
            onClick={toggleLanguageMenu}
            onKeyDown={e => {
              if (e.key === "Enter") toggleLanguageMenu()
            }}
            tabIndex={0}
            role="language-selection">
            <FontAwesomeIcon icon={faLanguage} />
            {languageMenu ? (
              <FontAwesomeIcon
                icon={faChevronUp}
                style={{ height: "12px", padding: "6px" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ height: "12px", padding: "6px" }}
              />
            )}
          </LanguageWrapper>
          {languageMenu && (
            <Dropdown>
              <UA
                current={language === Languages.UKRAINIAN}
                onClick={() => {
                  toggleLanguageMenu()
                  selectLanguage(Languages.UKRAINIAN)
                }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    toggleLanguageMenu()
                    selectLanguage(Languages.UKRAINIAN)
                  }
                }}
                role="українська мова"
                tabIndex={0}
              />
              <US
                current={language === Languages.ENGLISH}
                onClick={() => {
                  toggleLanguageMenu()
                  selectLanguage(Languages.ENGLISH)
                }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    toggleLanguageMenu()
                    selectLanguage(Languages.ENGLISH)
                  }
                }}
                role="english language"
                tabIndex={0}
              />
              <PL
                current={language === Languages.POLISH}
                onClick={() => {
                  toggleLanguageMenu()
                  selectLanguage(Languages.POLISH)
                }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    toggleLanguageMenu()
                    selectLanguage(Languages.POLISH)
                  }
                }}
                role="Język polski"
                tabIndex={0}
              />
            </Dropdown>
          )}
        </>
      )}
    </Navbar>
  )
}

const Navbar = styled.nav`
  background-color: rgba(206, 216, 226, 0.85);
  height: 40px;
  padding: 16px 32px 16px 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
`
const LanguageWrapper = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 16px;
  &:hover {
    background-color: ${colors.yellow};
    > svg {
      filter: none;
    }
  }
`
const FontAwesomeIcon = styled(fasIcon)`
  height: 24px;
  filter: invert(23%) sepia(44%) saturate(6229%) hue-rotate(343deg)
    brightness(79%) contrast(105%);
`
const Hamburger = styled(FontAwesomeIcon)`
  margin-left: auto;
  cursor: pointer;
  &:hover {
    filter: none;
    filter: invert(98%) sepia(22%) saturate(5743%) hue-rotate(3deg)
      brightness(102%) contrast(102%);
  }
`
const Xmark = styled(fasIcon)`
  cursor: pointer;
  height: 24px;
  color: white;
  &:hover {
    color: ${colors.yellow};
  }
`
const Popout = styled.aside`
  position: fixed;
  right: 0;
  tops: 0;
  bottom: 0;
  height: 100vh;
  width: 50vw;
  overflow: hidden;
  background-color: ${colors.red};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 0 16px;
  @media screen and (max-width: 400px) {
    > a {
      font-size: 0.75em;
    }
  }
  box-sizing: border-box;
  text-align: center;
  > * {
    margin: 8px 0;
    color: black;
    color: ${colors.offwhite};
  }
`
const Overlay = styled(Popout)`
  background-color: rgba(0, 0, 0, 0.75);
  left: 0;
  width: 50vw;
  cursor: pointer;
`
const UL = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  list-style-type: none;
  padding: 0;
  margin-right: 8px;
`
const Dropdown = styled.div`
  position: absolute;
  right: 40px;
  top: 72px;
`
const flag = `
  display: block;
  height: 20px;
  margin: 10px 5px;
  cursor: pointer;
`
const US = styled(Flags.US)<{ current: boolean }>`
  border: ${({ current }) =>
    current ? `2px solid ${colors.yellow}` : "2px solid transparent"};
  ${flag};
  &.mobile {
    border: ${({ current }) =>
      current ? `2px solid ${colors.yellow}` : "2px solid #555"};
    &:hover {
      border: 2px solid ${colors.yellow};
    }
  }
`
const UA = styled(Flags.UA)<{ current: boolean }>`
  border: ${({ current }) =>
    current ? `2px solid ${colors.yellow}` : "2px solid transparent"};
  ${flag};
  &.mobile {
    border: ${({ current }) =>
      current ? `2px solid ${colors.yellow}` : "2px solid #555"};
    &:hover {
      border: 2px solid ${colors.yellow};
    }
  }
`
const PL = styled(Flags.PL)<{ current: boolean }>`
  border: ${({ current }) =>
    current ? `2px solid ${colors.yellow}` : "2px solid transparent"};
  ${flag};
  &.mobile {
    border: ${({ current }) =>
      current ? `2px solid ${colors.yellow}` : "2px solid #555"};
    &:hover {
      border: 2px solid ${colors.yellow};
    }
  }
`
