import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faScaleBalanced,
  faSocks,
  faHouseChimneyMedical,
  faChildren,
  faPersonBreastfeeding,
} from "@fortawesome/free-solid-svg-icons"
import { P, H2, H4, Page, FlexRow, colors } from "components"
import { DeviceContext, DeviceTypes, LanguageContext } from "context"

export const About = () => {
  const device = React.useContext(DeviceContext)
  const ismobile =
    device.startsWith("mobile") || device === DeviceTypes.TABLET_PORTRAIT
  const { translation } = React.useContext(LanguageContext)
  const { about } = translation

  return (
    <AboutPage>
      <Content ismobile={ismobile}>
        <section>
          <div>
            <H2>
              {about.headline1}
              <br />
              {about.headline2}
              <span style={{ color: colors.red }}>{about.headline3}</span>
            </H2>
          </div>
          <div>
            <Intro>
              <i>{about.caritas}</i>
              {about.intro1}
              <i>{about.intro2}</i>
              {about.intro3}
              <i>{about.intro4}</i>
              {about.intro5}
              <i>{about.caritas} </i>
              {about.intro6}
            </Intro>
            <Intro>{about.intro7}</Intro>
          </div>
          <div>
            <IconCard>
              <FontAwesomeIcon icon={faScaleBalanced} />
              <P>{about.card1}</P>
            </IconCard>
            <IconCard>
              <FontAwesomeIcon icon={faSocks} />
              <P>{about.card2}</P>
            </IconCard>
            <IconCard>
              <FontAwesomeIcon icon={faHouseChimneyMedical} />
              <P>{about.card3}</P>
            </IconCard>
            <IconCard>
              <FontAwesomeIcon icon={faChildren} />
              <P>{about.card4}</P>
            </IconCard>
            <IconCard>
              <FontAwesomeIcon icon={faPersonBreastfeeding} />
              <P>{about.card5}</P>
            </IconCard>
          </div>
        </section>
        <section>
          <H2 style={{ textAlign: "center", marginBottom: "32px" }}>
            {about.titleAboutUs}
          </H2>
          <FlexRow
            style={ismobile ? { flexWrap: "wrap" } : { flexWrap: "nowrap" }}>
            <StaffCard>
              <Shadow />
              <img
                src="/images/war/IMG_8147.webp"
                alt={about.frVitalii}
                style={{ objectPosition: "10% 0%" }}
              />
              <figcaption>
                <H4>{about.frVitalii}</H4>
                <H4>{about.frVitaliiPosition}</H4>
                <P style={{ marginTop: "24px" }}>{about.frVitaliiDesc}</P>
              </figcaption>
            </StaffCard>
            <StaffCard>
              <Shadow />
              <img
                src="/images/sister.webp"
                alt={about.srFrantsyska}
                style={{ objectPosition: "center bottom" }}
              />
              <figcaption>
                <H4>{about.srFrantsyska}</H4>
                <H4>{about.srFrantsyskaPosition}</H4>
                <P style={{ marginTop: "24px" }}>{about.srFrantsyskaDesc}</P>
              </figcaption>
            </StaffCard>
          </FlexRow>
        </section>
      </Content>
    </AboutPage>
  )
}

const AboutPage = styled(Page)`
  background-color: ${colors.offblack};
  padding: 0 32px;
  overflow: auto;
`
const Content = styled.div<{ ismobile: boolean }>`
  max-width: 1200px;
  margin: 0 auto 32px;
  position: relative;
  top: 104px;
  color: ${colors.offwhite};
  z-index: -1;
  > section:first-of-type {
    display: flex;
    flex-flow: ${({ ismobile }) => (!ismobile ? "row wrap" : "column nowrap")};
    gap: 64px;
    margin-bottom: 64px;
    > div:nth-child(1) {
      flex: 1;
    }
    > div:nth-child(2) {
      flex: 1;
      > * {
        margin-bottom: 24px;
        > i {
          color: ${colors.red};
        }
      }
    }
    > div:nth-child(3) {
      width: 100%;
      display: flex;
      flex-flow: ${({ ismobile }) => (!ismobile ? "row nowrap" : "row wrap")};
      justify-content: center;
    }
  }
`
const Intro = styled(P)`
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: left;
  max-width: 748px;
  margin: 0 auto;
`
const IconCard = styled.figure`
  text-align: center;
  min-width: 125px;
  flex: 1;
  margin: 16px 16px 32px;
  > svg {
    color: ${colors.red};
    height: 64px;
    margin-bottom: 16px;
  }
`
const StaffCard = styled.figure`
  height: 500px;
  width: 300px;
  position: relative;
  margin: 16px 32px 64px;
  @media screen and (max-width: 500px) {
    margin: 16px -8px 64px;
  }
  @media screen and (min-width: 1000px) {
    margin: 16px 64px 64px;
  }
  overflow: hidden;
  &:hover {
    > img {
      transform: scale(1.2);
      opacity: 0.7;
    }
    > figcaption {
      transform: translateY(-16px);
    }
  }
  > img {
    transform: scale(1);
    transition: transform 2s, opacity 2s;
    height: 500px;
    width: 300px;
    object-fit: cover;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
  }
  > figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 300px;
    box-sizing: border-box;
    height: 275px;
    margin: 0;
    padding: 16px 32px;
    z-index: 4;
    transform: translateY(200px);
    transition: transform 2s;
  }
`
const Shadow = styled.div`
  height: 500px;
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  box-shadow: 0px 0px 25px ${colors.offblack} inset;
`
