import * as importEn from "./en/common.json"
import * as importUk from "./uk/common.json"
import * as importPl from "./pl/common.json"

interface translation {
  nav: Record<string, string>
  about: Record<string, string>
  donate: Record<string, string>
  home: Record<string, string>
  projects: Record<string, string>
  war: Record<string, string>
}

export interface EN extends translation {
  extra: Record<string, string>
}

export interface UK extends translation {
  extra: Record<string, string>
}

export interface PL extends translation {
  extra: Record<string, string>
}

const en: EN = importEn
const uk: UK = importUk
const pl: PL = importPl

export { en, uk, pl }
