import React from "react"
import { LanguageContext, Languages } from "context"

export const useTitle = (title?: string) => {
  const { language } = React.useContext(LanguageContext)
  let prefix
  switch (language) {
    case Languages.UKRAINIAN:
      prefix = title ? title : "Карітас-Спес"
      document.title = `${prefix} | Київсько-Житомирська Дієцезія`
      break
    case Languages.POLISH:
      prefix = title ? title : "Caritas Spes"
      document.title = `${prefix} | Diecezja Kijowsko-Żytomierska`
      break
    default:
      prefix = title ? title : "Caritas Spes"
      document.title = `${prefix} | Kyiv-Zhytomyr Diocese`
  }
}
