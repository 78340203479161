import React, { ReactChild } from "react"
import { useSearchParams } from "react-router-dom"
import { uk, en, pl, UK, EN, PL } from "translations"

export enum Languages {
  UKRAINIAN = "uk",
  ENGLISH = "en",
  POLISH = "pl",
}

interface LanguageContextRecord {
  language: Languages
  selectLanguage: (language: Languages) => void
  translation: UK | EN | PL
}

const LanguageContext = React.createContext<LanguageContextRecord>({
  language: Languages.ENGLISH,
  selectLanguage: lang => lang,
  translation: uk,
})

const handleInitial = (searchParams: URLSearchParams) => {
  const initial = Intl.DateTimeFormat().resolvedOptions().timeZone
  const langParam = searchParams.get("lang")
  if (langParam) return langParam as Languages

  switch (initial) {
    case "Europe/Kiev":
      return Languages.UKRAINIAN
    case "Europe/Warsaw":
      return Languages.POLISH
    default:
      return Languages.ENGLISH
  }
}

const LanguageContextProvider = ({ children }: { children: ReactChild }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [language, setLanguage] = React.useState<Languages>(
    handleInitial(searchParams),
  )
  const [translation, setTranslation] = React.useState(uk)

  React.useLayoutEffect(() => {
    switch (language) {
      case Languages.UKRAINIAN:
        setTranslation(uk)
        setSearchParams({ lang: "" })
        document.documentElement.setAttribute("lang", "uk")
        break
      case Languages.POLISH:
        setTranslation(pl)
        setSearchParams({ lang: Languages.POLISH })
        document.documentElement.setAttribute("lang", "pl")
        break
      default:
        setTranslation(en)
        setSearchParams({ lang: Languages.ENGLISH })
        document.documentElement.setAttribute("lang", "en")
        break
    }
  }, [language])

  const selectLanguage = (selection: Languages) => {
    setLanguage(selection)
  }

  return (
    <LanguageContext.Provider
      value={{
        language,
        selectLanguage,
        translation,
      }}>
      {children}
    </LanguageContext.Provider>
  )
}

export { LanguageContext, LanguageContextProvider }
