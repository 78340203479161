import React from "react"
import { createRoot } from "react-dom/client"
import { HashRouter } from "react-router-dom"
import { App } from "./App"
import { DeviceContextProvider, LanguageContextProvider } from "context"

const root = createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <HashRouter basename="/">
      <DeviceContextProvider>
        <LanguageContextProvider>
          <App />
        </LanguageContextProvider>
      </DeviceContextProvider>
    </HashRouter>
  </React.StrictMode>,
)
